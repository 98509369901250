import axios from 'axios';
class AxiosDataParser {
    jsonData;
    userMisc;
    customAuthHeader;
    timeToExpire;
    isViewType;
    constructor(jsonData, { customAuthHeader = null, timeToExpire = 0, userMisc = null }) {
        this.jsonData = jsonData;
        this.userMisc = userMisc;
        this.customAuthHeader = customAuthHeader;
        this.timeToExpire = timeToExpire;
        this.isViewType = [
            'list', 'retrieve'
        ].indexOf(jsonData.type || '') > -1;
    }
    /**
     * parse loading message from data
     * @param jsonData json data for axios
     * @returns loading message and loading status
     */
    axiosLoading() {
        const loadmapper = {
            create: 'Creating',
            update: 'Updating',
            delete: 'Deleting',
            retrieve: 'Getting Data',
            list: 'Getting Data',
            map: 'Getting Map',
            options: 'Getting Data',
            submitForm: 'Submitting Form'
        };
        return {
            loadingMessage: this.jsonData.loadingMessage || (typeof this.jsonData.type !== 'undefined' && this.jsonData.type in loadmapper
                ? loadmapper[this.jsonData.type]
                : ''),
            loading: this.jsonData.loading || false
        };
    }
    /**
     * check if user is authenticated or page / endpoint
     * allows for unauthenticated users
     *
     * @returns boolean
     */
    checkAuthenticated() {
        // check if user can go ahead to call the backend
        return (this.isViewType ||
            (Number(this.timeToExpire) > 0) ||
            (this.jsonData.unrestricted || false));
    }
    /**
     * Check if axios call type is parsed in correctly
     * @returns correct type. error if type is invalid
     */
    checkType() {
        if (!this.jsonData.type) {
            throw Error('Missing type');
        }
        const typeMap = {
            'retrieve': 'get',
            'list': 'get',
            'create': 'post',
            'update': 'put',
            'delete': 'delete'
        };
        // the type is not valid
        if (!typeMap[this.jsonData.type]) {
            throw Error('Invalid type');
        }
        return typeMap[this.jsonData.type];
    }
    /**
     * Get authentication header to be sent in to axios
     * @returns auth header
     */
    async getAuthHeader() {
        // append header if user is logged in
        let authHeaderDecision = {};
        if (Number(this.timeToExpire) > 0 &&
            !this.jsonData.disableAuth) {
            if (this.customAuthHeader !== null) {
                authHeaderDecision = {
                    ...authHeaderDecision,
                    ...this.customAuthHeader
                };
            }
            else {
                const { authHeader } = await import('./authHeader');
                authHeaderDecision = {
                    ...authHeaderDecision,
                    ...authHeader(this.isViewType, this.userMisc)
                };
            }
            return authHeaderDecision;
        }
    }
    /**
     * Build backend url to call using data in the json parsed in
     * @returns url
     */
    backendUrlBuilder() {
        const { main_url, url, urlType, id, handleSelf } = this.jsonData;
        const backendUrlBase = process.env.apimainUrl;
        let parsedUrl = url;
        // if handleself is set to true, ignore all parsing
        if (handleSelf) {
            return parsedUrl;
        }
        // if main_url is true, it means that the url includes
        // the backend app 
        if (main_url && !url.startsWith(backendUrlBase)) {
            parsedUrl = `${backendUrlBase}/${url}/`;
        }
        if (!main_url) {
            // else, append the backend app to the url
            const frontUrl = `${backendUrlBase}/${urlType
                ? urlType
                : 'spaces_core'}`;
            if (!url.startsWith(frontUrl)) {
                parsedUrl = `${frontUrl}/${url}/`;
            }
        }
        if (id) {
            parsedUrl += `${id.toString()}/`;
        }
        return parsedUrl;
    }
    /**
     * Parse params sent inside jsonData to
     * backend acceptable format
     * @returns backend acceptable params format
     */
    parseParams() {
        const { params } = this.jsonData;
        const returnParams = {};
        for (const key in params) {
            const typedKey = key;
            // for fields and expend, join the array
            if (typedKey === 'fields' || typedKey === 'expand') {
                returnParams[typedKey] = params[typedKey].join();
                continue;
            }
            // for filter type array, add "~" as separator
            if (typedKey === 'filter') {
                const filter = params.filter;
                if ((typeof filter !== 'undefined') && (Object.keys(filter).length === 0)) {
                    continue;
                }
                const filterArr = [];
                for (const i in filter) {
                    if (filter[i] === null ||
                        typeof filter[i] === 'undefined') {
                        continue;
                    }
                    filterArr.push(`${i}~${filter[i].toString()}`);
                }
                returnParams.filter = filterArr.join();
                continue;
            }
            returnParams[typedKey] = params[typedKey];
        }
        return returnParams;
    }
    /**
     * Handle error returned in axios
     * @param error Axios Error
     * @returns Standard keys with axios error for display
     */
    axiosErrorHandler(error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const responseData = error.response.data;
            let responseReason = '';
            // check if data returned is valid (dict)
            // reason is usually standardized when returning
            // custom 400 errors from backend
            if (typeof responseData === 'object' &&
                !Array.isArray(responseData) &&
                responseData !== null) {
                responseReason = responseData.reason || '';
            }
            return {
                error: {
                    message: 'Error from server',
                    code: error.response.status,
                    statusText: error.response.statusText,
                    reason: responseReason
                },
                success: false,
                data: null
            };
        }
        if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            return {
                error: {
                    message: 'No response from server',
                    code: 408,
                    statusText: '',
                    reason: ''
                },
                success: false,
                data: null
            };
        }
        // Something happened in setting up the request that triggered an Error
        return {
            error: {
                message: error.message,
                code: 0,
                statusText: '',
                reason: ''
            },
            success: false,
            data: null
        };
    }
    async backendCall() {
        // check if user is authenticated or page / endpoint
        // allows for unauthenticated users
        if (!this.checkAuthenticated()) {
            return {
                error: {
                    message: 'User is not authenticated'
                },
                success: false,
                data: null
            };
        }
        let apiMethod = '';
        try {
            apiMethod = this.checkType();
        }
        catch (err) {
            const errorMessage = err.message;
            return {
                error: {
                    message: errorMessage
                },
                success: false,
                data: null
            };
        }
        const authHeader = await this.getAuthHeader();
        const url = this.backendUrlBuilder();
        // create axios instance
        // with standard config. required especially
        // when calling from worker
        const httpClient = axios.create({
            timeout: url.includes('admin')
                // longer timeout for admin
                ? 300000
                : apiMethod !== 'get'
                    ? 30000
                    : 15000,
            withCredentials: process.env.MODE !== 'capacitor',
            headers: {
                common: {
                    'app-ver': process.env.release_version
                },
                ...authHeader
            }
        });
        try {
            // @ts-expect-error ignore
            const response = await httpClient({
                method: apiMethod,
                url,
                params: {
                    ...this.parseParams()
                },
                ...(!this.isViewType
                    ? {
                        // data not for 'get' methods
                        data: this.jsonData.data
                    } : {
                    // params only applies to 'get' methods
                    data: {}
                })
            });
            return {
                success: true,
                error: null,
                data: response.data
            };
        }
        catch (error) {
            const errorData = error;
            return this.axiosErrorHandler(errorData);
        }
    }
}
export { AxiosDataParser };
